import React, { useContext, useEffect, useState } from "react"
import { useCheckout } from "../global";
import { graphql, Link } from "gatsby"
import { Wrapper, Container, Grid, Card, media, below } from "../styles"
import styled, { css } from "styled-components"
import IconBar from "../components/IconBar"
import SEO from "../components/seo"
import Icon from "../components/Icon"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Button } from "../components/Button"
import Carousel from "../components/Carousel"
import SpecChart from "../components/legacy/SpecChart"
import { usePowerReviews } from "../hooks"
import weatherIntelImg from "../images/pages/product/blade-weather-intel-houses.jpg"
import { client } from "../lib/shopify";
import { Product, ProductVariant } from "shopify-buy"
import { ProLoginForm } from "../components/forms/ProLoginForm";
import { AuthContext, LoadingSpinner } from "@rachio-npm/rachio-retail-common";

const Headline = styled.h1`
  color: var(--primaryColor);
  margin-top: 0;
  font-size: 2.1em;
`

interface ShopifyProduct extends Product {
  availableForSale: boolean
}

type CarouselImage = {
  image: IGatsbyImageData,
  thumbnail: IGatsbyImageData,
  alt: string
};

type ProductImage = {
  altText: string
  fullSize: IGatsbyImageData,
  thumbnail: IGatsbyImageData
}

const VariantCard = styled(Card)`
  &:hover {
    cursor: pointer;
  }
`;

export const productImages = (images: { image: ProductImage }[]): CarouselImage[] => {
  const mapToCarouselImage = (img: ProductImage) => ({
    alt: img.altText as string,
    image: img.fullSize,
    thumbnail: img.thumbnail,
  });

  return images.map((media) => mapToCarouselImage(media.image));
};

const RachioThreePage = ({ data }) => {
  const { addVariantToCart }= useCheckout();
  const PRODUCT_HANDLE = "rachio-3-pro-series-3-pack";
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant | null>(null);
  const [availableForSale, setAvailableForSale] = useState<boolean>(false);
  const [product, setProduct] = useState<ShopifyProduct | null>(null);
  const { loggedIn, loading } = useContext(AuthContext);
  usePowerReviews(PRODUCT_HANDLE);

  async function fetchProduct() {
    try {
      const product = await client.product.fetchByHandle(PRODUCT_HANDLE);
      const shopifyProduct = product as ShopifyProduct;
      setProduct(shopifyProduct);
      setSelectedVariant(shopifyProduct.variants[0]);
      setAvailableForSale(shopifyProduct.availableForSale);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchProduct();
  }, [])

  const handleAddToCart = async () => {
    if (selectedVariant?.id) {
      await addVariantToCart(String(selectedVariant.id), 1);
      fetchProduct();
    }
  }

  if (loading) {
    return <LoadingSpinner />
  }

  if (!loggedIn) {
    return <ProLoginForm />
  }

  return (
    <>
      <SEO
        title="Rachio Pro Series (3 pack)"
        description="The Rachio Pro Series Wi-Fi Irrigation Controller is the fastest and simplest controller solution for a wide-range of indoor and outdoor applications. Use the Rachio app to setup smart schedules, Weather Intelligence Plus, and share access with customers. Pro Series controllers are only sold in 3 packs directly from Rachio and are shipped within 5-7 days of your purchase. Check with your local irrigation distributor for special deals, discounts, and same-day product pickup."
        pathname="/rachio-pro-series/"
      />
      <Wrapper>
        <Grid cols={[1, 1, 2]} css="padding-top: 0">
          <Container css={below.medium` padding-bottom: 0`}>
            <Carousel productImages={productImages(data.shopifyProduct.media)} />
          </Container>

          <div>
            <h6
              css={`
                margin: var(--margins);
                ${below.medium`
                margin-top: 0;
              `}
              `}
            >
              Indoor/Outdoor Wi-Fi Irrigation Controller
            </h6>
            <Headline>{data.shopifyProduct.title}</Headline>
            <div id="pr-reviewsnippet" />
            <div className="large" dangerouslySetInnerHTML={{ __html: data.shopifyProduct.descriptionHtml }} />
            <Grid cols="2">
              {product?.variants.map((variant: ProductVariant) => (
                <VariantCard
                  key={variant.id}
                  className={selectedVariant?.id === variant.id ? 'selected' : null }
                  data-testid="variant-card"
                  css="text-align: center; padding: 2rem;"
                  onClick={() => {
                    setSelectedVariant(variant);
                    setAvailableForSale(variant.available);
                  }}
                >
                  <p css="margin: 0">{variant.title.includes('8') ? '8-Zone (3-pack)' : '16-Zone (3-pack)'}</p>
                  <h3 css="margin: 0; color: var(--primaryColor)">${variant.price.amount}</h3>
                  <p className="subtext">{variant.sku}</p>
                </VariantCard>
              ))}
            </Grid>
            <Button
              css="width: 100%; margin: 3rem 0 1rem"
              disabled={!selectedVariant?.available}
              onClick={handleAddToCart}
              data-testid="button-add-to-cart"
            >
              {!selectedVariant?.available ? 'Not Available' : 'Add to Cart'}
            </Button>
            <p style={{ textAlign: 'center', marginBottom: '3rem' }}>
              Rachio is available at most irrigation distributors. <Link to="/where-to-buy/">Click here</Link> to see if there is one in your area.
              Please contact your local distributor for best pricing, in-store availability, terms, and rewards.
            </p>
          </div>
        </Grid>
      </Wrapper>
      <IconBar icons={icons} isBlue />
      <Wrapper>
        <ReverseGrid cols={[1, 1, 2]}>
          <div className="mobile-reverse-item">
            <h2
              css={`
                color: var(--primaryColor);
                ${below.medium`
                margin-top: 0;
              `};
              `}
            >
              Everything you need to install
            </h2>
            <p>
              The Pro Series controller comes with everything you might need to install
              a Rachio quickly and reliable components that limit points of failure.
            </p>
            <IconList>
              <li>
                <Icon color="var(--primaryColor)" name="check" />
                <p css="display: inline">
                  Wire-ready barrier block terminals
                </p>
              </li>
              <li>
                <Icon
                  style={{ width: "36px", height: "36px", marginRight: "1rem" }}
                  color="var(--primaryColor)"
                  name="check"
                />
                <p>Lockable cabinet</p>
              </li>
              <li>
                <Icon color="var(--primaryColor)" name="check" />
                <p>Weatherproof outdoor enclosure</p>
              </li>
              <li>
                <Icon color="var(--primaryColor)" name="check" />
                <p>Includes 6-foot pigtail</p>
              </li>
              <li>
                <Icon color="var(--primaryColor)" name="check" />
                <p>Eligible for Pro Rewards (coming soon)</p>
              </li>
            </IconList>
          </div>
          <div>
            <GatsbyImage
              image={getImage(data.r3InsideImg.childImageSharp.gatsbyImageData) as IGatsbyImageData}
              alt="Inside view of Rachio Pro Series Controller"
            />
          </div>
        </ReverseGrid>
      </Wrapper>

      <Wrapper>
        <ImgContainer img={weatherIntelImg}>
          <Wrapper
            as={Grid}
            cols={[1, 1, 2]}
            css={`
              ${below.medium`
                grid-gap: 0;
                padding: 0;
                > div {
                  padding-bottom: 0;
                }
              `}
            `}
          >
            <Container
              css={`
                ${media.medium`
                color: var(--white);
              `}
              `}
            >
              <h3>Worry-free watering</h3>
              <p>
                The Rachio App acts as a personal watering assistant, helping contractors quickly set up and manage schedules from anywhere.
                Homeowners love the app because they can check their schedule or start a Quick Run with a few taps.
              </p>
              <p>
                As an added bonus for your clients, the Pro Series comes with Weather Intelligence Plus,
                which uses local weather data to modify their watering schedules. If it’s rained recently
                or is expect to rain in the near future, Rachio can skip that day’s watering. The same goes
                for days where the temperature is set to fall below freezing or high winds are expected.
                All of these features help the planet by reducing water waste and help homeowners keep their yard in great shape.
              </p>
            </Container>
            <Container>
              <MobileProductImg
                image={getImage(data.weatherIntelMobileImg.childImageSharp.gatsbyImageData) as IGatsbyImageData}
                alt="Weather Intel background image"
              />
            </Container>
          </Wrapper>
        </ImgContainer>
      </Wrapper>
      <Wrapper width="tight" css="text-align: center">
        <h3 css="color: var(--primaryBlue)">
          Three steps to smarter watering.
        </h3>
        <IconBar icons={stepIcons} />
      </Wrapper>
      <div css="background: var(--lightesterGray)">
        <Wrapper width="tight">
          <Container
            css={below.medium`
            padding-top: 6rem;
          `}
          >
            <h2 css="text-align: center; margin-top: 0">
              Rachio Pro Series Technical Specifications
            </h2>
            <SpecChart specs={rProSpecs} />
          </Container>
        </Wrapper>
      </div>
    </>
  )
}

export default RachioThreePage

const icons = [
  {
    iconName: "support",
    title: "Best-in-class support",
  },
  {
    iconName: "truck",
    title: "Free shipping & returns",
  },
  {
    iconName: "heart",
    title: "Extended 4-Year Warranty on Pro Units",
  },
  {
    isRebate: true,
    iconName: "money",
    title: "Rebates available",
  },
]

const stepIcons = [
  {
    iconName: "replace-controller",
    title: "1. Replace your customers old controller.",
  },
  {
    iconName: "plug",
    title: "2. Plug existing wires into Rachio.",
  },
  {
    iconName: "app-touch",
    title: "3. Complete setup in the Rachio app.",
  },
]

export const rProSpecs = [
  {
    title: "In the box",
    descriptionList: [
      "Rachio Pro Series Controller with wire-ready barrier block terminals",
      "Weatherproof enclosure",
      "Power supply and 6-foot pigtail",
      "Mounting hardware",
      "Quick start guide"
    ],
  },
  {
    title: "App Compatibility",
    description:
      "iOS 10.3+ and Android 4.4+; subject to change. Mobile app is required to connect the controller to Wi-Fi. Web app available on most browsers.",
  },
  {
    title: "Integrations",
    description:
      "Works with Amazon Alexa, IFTTT, Wink, Control4, Nexia and others",
  },
  {
    title: "Zones",
    description: "8-zone and 16-zone wire-ready terminals",
  },
  {
    title: "Operating Temperature",
    description: "Operating temperature: -4°F to 140°F",
  },
  {
    title: "Wi-Fi Connection",
    description:
      "2.4 or 5 GHz wireless network signal available at the installation location",
  },
  {
    title: "Sensors",
    descriptionList: [
      "Rain Sensor: Wired & Wireless Normally Closed (NC) sensors",
      "Soil Sensor: Wired & Wireless Normally Closed (NC) sensors",
      '<a target="_blank" href="https://support.rachio.com/en_us/wired-flow-sensor-compatibility-and-installation-Syo0DIyYw">Wired Flow Sensors (EVERYDROP, FLOWMEC, Badger, CST, Toro)</a>',
    ],
  },
  {
    title: "Wire Terminals",
    description:
      "Smart wire terminals sense which zones are active, and provide feedback if your customers irrigation system might have a problem",
  },
  {
    title: "Master Valve",
    description: "Compatible with master valve, pump relay, or indexing valve",
  },
  {
    title: "Warranty",
    description: "Extended 4-year warranty when purchasing Pro Units/SKUs",
  },
  {
    title: "Power Disruption",
    description:
      "In the event of Wi-Fi outage or intermittency, the controller’s memory retains the last saved schedule received from the Rachio cloud and maintains the schedule until an update can be downloaded",
  },
]

export const query = graphql`
  query {
    shopifyProduct(
      handle: {eq: "rachio-3-pro-series-3-pack"}
    ) {
      shopifyId
      handle
      title
      descriptionHtml
      availableForSale: totalInventory
      media {
        ... on ShopifyMediaImage {
          image {
            fullSize: gatsbyImageData(
              width: 790
              placeholder: TRACED_SVG
              formats: [WEBP, AUTO]
            )
            thumbnail: gatsbyImageData(
              width: 70
              placeholder: TRACED_SVG
              formats: [WEBP, AUTO]
            )
            altText
          }
        }
      }
    }

    # Masthead images
    ## Image Note: Actual image is 1300px wide
    r3Img: file(relativePath: { eq: "products/r3/R38_front_light.jpg" }) {
      ...fluidImage790
    }
    r3InsideImg: file(
      relativePath: { eq: "pages/home/r3ps-2.png" }
    ) {
      ...fluidImage790
    }
    r3ContentsImg: file(
      relativePath: { eq: "products/r3/R38_box_contents.jpg" }
    ) {
      ...fluidImage790
    }
    r3AngledImg: file(relativePath: { eq: "products/r3/r3-angled.jpg" }) {
      ...fluidImage790
    }
    r3LightOffImg: file(relativePath: { eq: "products/r3/R316_front.jpg" }) {
      ...fluidImage790
    }
    # Masthead thumbnails
    r3Thumbnail: file(relativePath: { eq: "products/r3/R38_front_light.jpg" }) {
      ...fixedImage75x75
    }
    r3InsideThumbnail: file(
      relativePath: { eq: "products/r3/R38_box_contents.jpg" }
    ) {
      ...fixedImage75x75
    }
    r3ContentsThumbnail: file(
      relativePath: { eq: "products/r3/R38_box_contents.jpg" }
    ) {
      ...fixedImage75x75
    }
    r3AngledThumbnail: file(relativePath: { eq: "products/r3/r3-angled.jpg" }) {
      ...fixedImage75x75
    }
    r3LightOffThumbnail: file(
      relativePath: { eq: "products/r3/R316_front.jpg" }
    ) {
      ...fixedImage75x75
    }
    #
    pegboardMobileImg: file(
      relativePath: {
        eq: "pages/product/product-blade-rachio3-pegboard-mobile.jpg"
      }
    ) {
      ...fullFluidImage
    }
    ## Image note: width is 700 but needs to be 790
    weatherIntelMobileImg: file(
      relativePath: {
        eq: "pages/product/blade-weather-intel-houses-mobile.jpg"
      }
    ) {
      ...fluidImage790
    }
    #
    phoneAppImg: file(relativePath: { eq: "pages/product/phone-app.png" }) {
      ...fluidImage380
    }
    #
    r3FlowPhoneImg: file(
      relativePath: { eq: "pages/product/product-rachio3-flow-phone.png" }
    ) {
      ...fluidImage790
    }
  }
`

const PowerReviewsContainer = styled(Container)`
  /* Hide the customer provided review images and gallery */
  & #pr-image-display,
  & .pr-rd-images {
    display: none;
  }
`

export const MobileProductImg = styled(GatsbyImage)`
  display: none;
  ${below.medium`
    display: block;
  `};
`

export const SmallProductImg = styled(GatsbyImage)`
  display: none;

  ${below.small`
    display: block;
  `};
`

const ImgContainer = styled.div`
  ${({ img }: { img: string }) => css`
    background: none;
    ${media.medium`
    background: url(${img}) no-repeat;
    background-size: cover;
  `}
  `};
`

const ReverseGrid = styled(Grid)`
  align-items: center;
  padding: var(--topSectionPadding) 0 var(--bottomSectionPadding) 0;
  ${below.medium`
    .mobile-reverse-item  {
      grid-row: 2;
    }
  `};
`

const IconList = styled.ul`
  li {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    svg {
      flex: none;
      height: 36px;
      width: 36px;
      margin-right: 1rem;
    }
  }
`
